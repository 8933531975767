var ct = 0;
$(window).scroll(function() {

  $('header').each(function() {
    var hh = $('header').height();
    
    var top = $(window).scrollTop(),
        h = $(window).height(),
        header = $('header');
    if (!$('body').hasClass('menu-open')) {
      if (top > hh) {
        header.addClass('fixed');
        $('.cart').removeClass('in');
      } else {
        header.removeClass('fixed')
      }
      if (ct > top) {
        header.addClass('sticky');
      } else {
        header.removeClass('sticky');
      }
      if (top > h) {
        jQuery('.back-to-top').removeClass('h');
      } else {
        jQuery('.back-to-top').addClass('h');
      }
      ct = top;
    }
    
  });
});

$('.icon-menu').click(function(){
  $('body').toggleClass('menu-open');
})


$('.slider').each(function () {
  var t = $(this);

  t.addClass('owl-carousel');
    t.owlCarousel({
      navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
      items: 1,
      loop: true,
      nav: false,
      dots: true,
      autoplay: true,
      autoplayTimeout: 5000,
      smartSpeed: 1500
    });
});

//SLIDER EXTRA SMALL
function sliderXS() {
  var sliderS = $('.slider-xs');
  if ($(window).width() < 769) {
    sliderS.addClass('owl-carousel');
    sliderS.owlCarousel({
      navText: ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
      items: 1,
      loop: true,
      nav: true,
      dots: false,
      autoplay: true,
      responsive : {
        0 : {
          items: 1
        },
        480 : {
          items: 1
        }
      }
    });
  } else {
    sliderS.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
    sliderS.find('.owl-stage-outer').children().unwrap();
  }
}sliderXS();

function sliderAbout(){
  var sliderS = $('.slider-social')

  sliderS.addClass('owl-carousel');
  sliderS.owlCarousel({
    navText: ["<i class='fa fa-arrow-left'></i>","<i class='fa fa-arrow-right'></i>"],
    items: 2,
    dotsEach: true,
    loop: true,
    nav: true,
    dots: true,
    autoplay: false,
    responsive : {
      0 : {
        items: 1
      },
      480 : {
        items: 2
      }
    }
  });
}sliderAbout();


function setMH(){
  $('.matchs').find('.matchr').matchHeight();
  $('.matchs').find('.match').matchHeight({
    byRow: false
  });
}setMH();


//ON WINDOW RESIZE
$(window).resize(function() {
  setMH();
  sliderXS();
});

  
  // to top right away
if ( window.location.hash ) scroll(0,0);
// void some browsers issue
setTimeout( function() { scroll(0,0); }, 1);

$(function() {

    // your current click function
    $('.scroll').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top + 'px'
        }, 1000);
    });

    // *only* if we have anchor on the url
    if(window.location.hash) {

        // smooth scroll to the anchor id
        $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top + 'px'
        }, 1000);
    }

});

// sidenav

  $('ul.tab-nav').each(function(){
  var t = $(this),
      a = t.find('a'),
      html = "",
      select = $('<select class="select select-nav">'),
      st = $("<div class='select-tab' />");

  a.each(function(){
    var link = $(this).attr('href');
    console.log(link);
    html+="<option value='"+link+"'>"+$(this).text()+"</option>";
  })

  select.html(html);
  st.append(select);
  st.insertAfter(t);

  $('select.select').selectpicker({
    style: 'select-control',
    width: '100%',
    size: 5
  });
});

// Dropdown link
  $('.select-nav').on('change', function () {
    var url = $(this).val();
    if (url) {
      window.location = url;
    }
    return false;
  });

// Accordion Slide

$('.stp').each(function(){
  var t = $(this),
      item = t.find('.item');
  
  item.click(function(){
    item.removeClass('open');
    $(this).addClass('open');
  });
  item.hover(function(){
    item.removeClass('open');
    $(this).addClass('open');
  });
});

// Line Animation

function lines() {
  $('.line').each(function(index, e) {
    var elm = $(e);
    var elmInside = $(e).find('>div');
    var direction = elm.data('direction');
    var duration = elm.data('duration');
    var delay = elm.data('delay');
    var start = elm.data('start');
    var top = elm.data('top');
    var mov = elm.data('mov');
    var width = elm.data('width');
    var height = elm.data('height');
    var onover = elm.data('hover')? elm.data('hover') : false;

    elm.attr('data-onh',onover);


    if (direction == 'left') {
      var origin = 'right top 0px';
      var to = 'left top 0px';
      elm.css({
        'right': start + 'px',
      });
    } else {
      var origin = 'left top 0px';
      var to = 'right top 0px';
      elm.css({
        'left': start + 'px',
      });
    }

    elm.css({
      'top': top + 'px',
    });

    elmInside.css({
      'width': width + 'px',
      'height': height + 'px',
      'background': elm.data('color'),
      '-webkit-transform-origin': origin,
      '-moz-transform-origin': origin,
      '-ms-transform-origin': origin,
      'transform-origin': origin,
      'transform': 'scaleX(0)'
        /*
        '-webkit-transition': '-webkit-transform '+duration+'ms ease-in-out',
        '-moz-transition': '-moz-transform '+duration+'ms ease-in-out',
        '-ms-transition': '-ms-transform '+duration+'ms ease-in-out',
        'transition': 'transform '+duration+'ms ease-in-out'
        */

    });

    // Initial scale from 0 to 1
    var scale = [1, 0];


    function loop() {
      // ANIM LINE 
      if (direction == 'left') {
        elm.velocity({
          'right': mov + 'px',
        }, {
          duration: 600,
          easing: "ease",
          delay: delay,
          complete: function() {
            elm.css({
              'right': start + 'px',
            });
          }
        });
      } else {
        elm.velocity({
          'left': mov + 'px'
        }, {
          duration: 600,
          easing: "ease",
          delay: delay,
          complete: function() {
            elm.css({
              'left': start + 'px',
            });
          }
        });
      }

      // ANIM INSIDE LINE
      elmInside.velocity({
        scaleX: scale,
      }, {
        duration: 600,
        easing: "ease",
        delay: delay,
        complete: function() {
          elmInside.css({
            'transform-origin': to,
            'transition': ''
          });
          elmInside.velocity({
            scaleX: 0
          }, {
            duration: 600,
            easing: "ease",
            delay: 0,
            complete: function() {
              elmInside.css({
                'transform-origin': origin
              });
              scale = 1;
              loop();
            }
          });
        }
      });
    }
    
    if(onover){
      var par = elm.closest('[data-parent]');
      elmInside.css({
        'transform': 'scaleX(1)'
      })
      par.mouseenter(function(){
        loop();
      });
      par.mouseleave(function(){
        
        var sx = elmInside.css('transform');
        var values = sx.split('(')[1];
        values = values.split(')')[0];
        values = values.split(',');
        console.log(values[0]);
        if(values[0]<0.5){
          elmInside.velocity('stop').velocity({
            duration: 600,
            easing: "ease",
            scaleX: 0,
            complete: function() {
              elmInside.css({
                'transform-origin': to,
                'transition': ''
              });
              elmInside.velocity({
                scaleX: 0
              }, {
                duration: 600,
                easing: "ease",
                delay: 0,
                complete: function() {
                  elmInside.css({
                    'transform-origin': origin
                  });
                  elmInside.velocity({
                    scaleX: 1
                  }, {
                    duration: 600,
                    easing: "ease",
                    delay: 0,
                    complete: function() {
                      elmInside.css({
                        'transform-origin': to
                      });
                      scale = 1;
                    }
                  });
                }
              });
            }
          })
        }else{
          elmInside.velocity('stop').velocity({
            duration: 100,
            easing: "ease",
            scaleX: 1
          })
        }


        
      })
    }else{
      elm.addClass('halo');
      loop();
    }
    

  });
}lines();

// Input File

$('.inputfile').each(function(){
  var t = $(this),
      input = t.find('input'),
      btn = t.find('.btn'),
      info = t.find('.fileinfo'),
      del = t.find('.btn-delfile');
      
  input.change(function(e){
    var fileName = '';
    
    if(this.files && this.files.length > 1){
      fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
    } else {
      fileName = e.target.value.split('\\').pop();
    }
    
    if(fileName){
      info.html(fileName);
    }

    t.addClass('has-file');
    
  });
  
  del.click(function(){
    info.html('Choose file');
    input.val('');
    t.removeClass('has-file');
  });

});

// Expand text
 $('.collapse-group').each(function(){
    var t = $(this),
        box = t.find('.box-expand'),
        pl = t.find('.parts-left'),
        plH = pl.height(),
        pr = t.find('.parts-right'),
        prH = pr.height(),
        ac = $("<a class='btnmore'><div class='line-group'></div></a>").text('View more'),
        p1 = t.find('p').eq(0).outerHeight(true),
        p2 = t.find('p').eq(1).outerHeight(true),
        p3 = t.find('p').eq(2).height(),
        btn = t.find('.btnmore');
    if(plH > 750){
      box.css('max-height', p1+p2+p3);
      box.addClass('collapse');
      pl.append(ac);     
    }
 });
  $('.collapse-group').each(function(){
    var t = $(this),
        box = t.find('.box-expand'),
        p1 = t.find('p').eq(0).outerHeight(true),
        p2 = t.find('p').eq(1).outerHeight(true),
        p3 = t.find('p').eq(2).height(),
        btn = t.find('.btnmore');
    btn.click(function(){
      box.toggleClass('open');
      if(box.hasClass('open')){
        btn.html('View less');
        box.css('max-height', 9999);
      }else{
        btn.html('View more');
        box.css('max-height', p1+p2+p3);
        $('html, body').animate({
              scrollTop: t.offset().top - 100
          }, 1000);
      }
    })
 });

// post succsess scroll to section Enroll
$(document).bind('gform_post_render', function(){
  $('html, body').animate({scrollTop: $(".side-form").offset().top - 300}, 500); 
});


// // post succsess scroll to section
// $('#gform_5').submit(function(){
//   // console.log('succsess2');
//   $('html, body').animate({scrollTop: $(".side-form").offset().top - 200 }, 500);
// });


// Update js Form Dev

function init_select(){
    $('select.select').attr('data-live-search','true');
//    $('select.select').attr('data-live-search-placeholder','Search Country');
    $('select.select').attr('data-live-search-normalize','true');
    $('select.select').each(function() {
      var s = $(this).data('size'),
          w = $(this).data('width');
      if( s == undefined ){
        s = 5;
      }
      if( w == undefined ){
        w = 100+'%';
      }
      $(this).selectpicker({
        style: 'select-control',
        width: w,
        size: s
      });
    });
}
init_select();